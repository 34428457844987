.App {
	text-align: center;
	height: 100vh;
	display: flex;
	flex-direction: column !important;
	justify-content: center;
	align-items: center;
	font-family: 'Fjalla One', sans-serif;
}
.titles {
	display: flex;
	justify-content: space-around;

	flex-direction: column;
}
.content {
	position: relative;
}
.social {
	color: white;
}
